import React from 'react'
import Snuggle from 'react-snuggle'
import * as UI from '@/components/UI'
import { Section, Grid, ImageCell, TextCell, List, Item } from './Learn.css'

const Learn = ({ content }) => {
  return (
    <Section>
      <UI.Container>
        <Grid>
          <Snuggle container={<ImageCell />}>
            {content.learn_image.map(img => {
              return <UI.Image src={img.image} />
            })}
          </Snuggle>
          <TextCell>
            <UI.SectionTitle>{content.learn_title}</UI.SectionTitle>
            <UI.SectionDescription>
              {content.learn_description}
            </UI.SectionDescription>
            <List>
              {content.learn_list.map(({ item }) => (
                <Item key={item}>{item}</Item>
              ))}
            </List>
          </TextCell>
        </Grid>
      </UI.Container>
    </Section>
  )
}

export default Learn
