import * as UI from '@/components/UI'
import React from 'react'
import { Section, SectionTitle, WrapperSection } from './Style.css'
import { RichText } from '../ArticleTemplate/TextSlice.css'

const TextSection = ({ content }) => {
  const html = content?.section?.[0]?.description?.html
  if (!html) return null
  return (
    <Section>
      <UI.Container style={{ maxWidth: '704px' }}>
        {content?.section.map(val => {
          return (
            <WrapperSection key={val.title}>
              <SectionTitle>{val.title}</SectionTitle>
              <RichText
                dangerouslySetInnerHTML={{ __html: val.description.html }}
              />
            </WrapperSection>
          )
        })}
      </UI.Container>
    </Section>
  )
}

export default TextSection
