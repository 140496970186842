import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  padding: 2rem 0;
  ${above('md')`
    padding: 5rem 0;
  `}
`

export const Grid = styled(UI.Grid)`
  align-items: center;
`

export const ImageCell = styled.div`
    grid-template-columns: calc(50% - 20px) 50%!important;
    width: ${getColumnSize(12)};
  ${above('md')`
    width: ${getColumnSize(12)};
    max-width: 600px;
    margin: 0 auto;
  `}
  ${above('lg')`
    margin-left: -${getColumnSize(1)};
    width: ${getColumnSize(6)};
  `}

  & > * {
    width: 100%;
    height: 100%;

    &:nth-child(n+3) {
      display: none;
    }

    ${above('md')`
      display: block !important;
    `}
`

export const TextCell = styled.div`
  width: ${getColumnSize(12)};
  margin-top: 3rem;
  margin-bottom: 3rem;
  ${above('md')`
    width: ${getColumnSize(12)};
    margin-left: ${getColumnSize(1)};
  `}
  ${above('lg')`
    width: ${getColumnSize(5)};
  `}
`

export const List = styled.ul`
  padding-top: 2rem;
`

export const Item = styled.li`
  margin-bottom: 1rem;
  ${getTypeStyle('smallText')};
  display: flex;
  position: relative;
  &:before {
    content: '—';
    color: ${p => p.theme.colors.orange};
    margin-right: 0.5rem;
    ${above('lg')`
      position: absolute;
      right: 100%;
    `}
  }
`
