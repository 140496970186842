import * as UI from '@/components/UI'
import React from 'react'
import { Text } from '@/components/UI/Typography/Typography'
import { Section, WrapperInner, Wrapper } from './Metrics.css'

const Metrics = ({ content }) => {
  if (content.metrics.length < 0) return null

  const { length } = content.metrics

  return (
    <Section>
      <UI.Container>
        <Wrapper length={length}>
          {content.metrics.map(val => {
            return (
              <WrapperInner key={val.title}>
                <UI.SectionTitle>{val.title}</UI.SectionTitle>
                <Text>{val.description}</Text>
              </WrapperInner>
            )
          })}
        </Wrapper>
      </UI.Container>
    </Section>
  )
}

export default Metrics
