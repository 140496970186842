import styled from 'styled-components'
import above from '@/utils/above'

export const Section = styled.section`
  padding: 2rem 0;
  ${above('md')`
    padding: 5rem 0;
  `}
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  ${above('md')`
  grid-template-columns: repeat(2, 1fr);
`}
  ${above('lg')`
grid-template-columns: ${p => `repeat(${p.length}, 1fr)`}
`}
`

export const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 32px;
  &:not(:last-child) {
    border-bottom: 1px solid ${p => p.theme.colors.maastricht}10;
  }

  ${above('md')`
  grid-template-columns: repeat(2, 1fr);
  &:not(:last-child) {
    border-bottom: 0px;
  }
`}
  ${above('lg')`
  &:not(:last-child) {
    border-right: 1px solid ${p => p.theme.colors.maastricht}10;
  }
`}
`
