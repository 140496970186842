import styled from 'styled-components'
import above from '@/utils/above'
import getTypeStyle from '@/utils/getTypeStyle'

export const Section = styled.section`
  padding: 2rem 0;
  ${above('md')`
    padding: 5rem 0;
  `}
`

export const SectionTitle = styled.h3`
  font-family: ${p => p.theme.type.fonts.headline};
  ${getTypeStyle('subtitle')};
  margin-bottom: 32px;
`

export const WrapperSection = styled.div`
  margin-bottom: 80px;
`
