import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'

export const Section = styled.section`
  margin: 4rem auto;
`

export const Cell = styled.div`
  margin: auto;
  width: ${getColumnSize(12)};
  ${above('sm')`
    width: ${getColumnSize(10)};
  `}
  ${above('md')`
    width: ${getColumnSize(8)};
  `}
  ${above('xg')`
    width: ${getColumnSize(6)};
  `}
`

export const RichText = styled.div`
  font-size: 19px;
  line-height: 1.875rem;
  color: ${p => p.theme.colors.maastricht}CC;
  ul,
  ol {
    margin: 0.75rem 0 2rem;
    list-style: disc;
  }
  p + p {
    margin-top: 0.75rem;
  }
  ol {
    list-style: decimal-leading-zero;
  }
  li {
    list-style-position: inside;
  }
  h2 {
    color: ${p => p.theme.colors.maastricht};
    font-weight: 500;
    font-family: ${p => p.theme.type.fonts.headline};
    margin: 4rem 0 2rem;
    ${getTypeStyle('caption')}
    ${above('md')`
      ${getTypeStyle('subtitle')}
    `}
  }
  a {
    color: ${p => p.theme.colors.blue};
    text-decoration: underline;
  }
`
