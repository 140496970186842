import * as UI from '@/components/UI'
import React from 'react'
import { SmallText } from '@/components/UI/Typography/Typography'
import { Section } from './Style.css'

const ImageSection = ({ content }) => {
  return (
    <Section>
      <UI.Container>
        <UI.Image src={content.image} />
        {content.image_legend && (
          <SmallText style={{ marginTop: '1rem' }} color='gray'>
            {content.image_legend}
          </SmallText>
        )}
      </UI.Container>
    </Section>
  )
}

export default ImageSection
